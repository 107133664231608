// console.log('yooo');
// document.addEventListener("DOMContentLoaded", function() {
//   var elements = document.querySelectorAll('.dir-listing-border');
//   console.log(elements);

//   elements.forEach(function(el) {

//       var wrapper = document.createElement('div');
//       wrapper.classList.add('listing-members');

//       el.parentNode.insertBefore(wrapper, el);
//       wrapper.appendChild(el);
//   });
// });


document.addEventListener('DOMContentLoaded', function() {
  var specialites = {
      'medecine-familiale': 'Médecine familiale',
      'physiatrie': 'Physiatrie',
      'orthopedie' : 'Orthopédie',
      'chirurgie-plastique' : 'Chirurgie plastique',
      'pediatrie' : 'Pédiatrie',
      'rhumatologie' : 'Rhumatologie',
      'radiologie' : 'Radiologie',
      'medecine-nucleaire' : 'Médecine nucléaire',
      'urgence' : 'Urgence',
      'autre' : 'Autre',
  };

  document.querySelectorAll('.dud_line_1').forEach(function(lineElement) {
    var fieldElement = lineElement.querySelector('.dud_field_1');
    if (fieldElement) {
        var slug = fieldElement.textContent.trim();
        if (specialites[slug]) {
            fieldElement.textContent = specialites[slug];  // Remplacer le slug par la valeur lisible
            lineElement.classList.add('speciality-name');  // Ajouter la classe à dud_line_1
        }
    }
  });

  // const memberCards = document.querySelectorAll('.dir-listing-border');
  // memberCards.forEach(function(memberCard) {
  //   memberCard.classList.add('js-delegate-link');
  // });
});
